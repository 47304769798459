<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">Empower Your Digital Presence</h1>
          <p class="para-desc text-dark">Elevate Your Business with Our Comprehensive Software Solutions</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/saas/it_services-front.png" class="mx-auto d-block" alt="">
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->


<!-- Section Start -->
<section class="section overflow-hidden">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/1-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Elrange Energy Website</h1>
          <p class="para-desc text-dark">Crafted by our skilled professionals, an enterprise website developed for
            Elrange Energe LLP based in Bangalore</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless hosting</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Brochure PDF export</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Whatsapp chat integration</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Sharacircuits Website</h1>
          <p class="para-desc text-dark">Our team proudly presents a recently developed website for Shara Circuits Pvt
            Ltd, an esteemed PCB manufacturing company located in Bangalore.</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless hosting</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Instant quote feature</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Payment gateway integration</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Whatsapp chat integration</li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/2-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/3-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Greeneria smart water metering webapp</h1>
          <p class="para-desc text-dark">We've just launched a cutting-edge web application tailored for Greeneria
            Smart Water's administrative needs. Experience seamless management and enhanced efficiency with our
            innovative solution</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless hosting</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Extremely cost optimized serverless IoT backend
            </li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Report exports in Excel, CSV, PDF</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Email and SMS offline alerts</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">BMA Flower Depot Billing tool</h1>
          <p class="para-desc text-dark">Introducing our latest achievement: an end-to-end billing application
            meticulously crafted for BMA Flor Depot, serving all stalls within Bangalore's renowned KR Market. Simplify
            your billing processes and streamline operations with our comprehensive solution.</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Custom report generation and PDF exports</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Tree based user management on platform</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Extremely cost optimized serverless IoT backend
            </li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Whatsapp and SMS alerts</li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/4-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/5-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">SVS title services</h1>
          <p class="para-desc text-dark">Presenting the new website for SVS Title Services, designed to streamline your
            property title needs with ease</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Website loading cache optimized</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>HTTPS hosting on server based backend</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Smart silo monitoring webapp for Zveno Infratech</h1>
          <p class="para-desc text-dark">Introducing our latest innovation: a smart silo monitoring solution for Zveno
            Infratech, revolutionizing cement level monitoring for companies in Chennai like ACC and others. Stay
            informed and optimize operations with real-time data insights.</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>IoT integration to read sensor data from level
              sensor</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless backend with batch opmization</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Custom frontend charts for level indication
            </li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Whatsapp and SMS alerts</li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/6-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/7-front.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Greeneria Website</h1>
          <p class="para-desc text-dark">Welcome to the newly launched website for Greeneria Renewable Technology Pvt
            Ltd in Bangalore! Discover their comprehensive waste management solutions, including OWC and biogas plants,
            trusted by industry leaders. Join us in promoting sustainability and efficient resource utilization</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless hosting</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Email generation for requesting quote</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Frontend load time optimized</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Smart water metering mobile app for Greeneria</h1>
          <p class="para-desc text-dark">Explore the brand-new mobile app for Greeneria Smart Water Meter, your go-to
            solution for monitoring water consumption details. Powered by Connectchip's white-labeled water meters, our
            platform ensures accurate data insights. Available on both the Play Store and iOS App Store for seamless
            access.</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Hosted on both Play Store and iOS store</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>completely serverless backend IoT platform</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Zero frontend-backend platform down time
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/01-portrait.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/02-portrait.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Pocket Heart mobile app for Sujatha Healthcare</h1>
          <p class="para-desc text-dark">Introducing Pocket Heart, the revolutionary mobile app developed for Sujatha
            Healthcare. Seamlessly integrated with biomedical devices, it reads ECG and other vital signals, offering
            in-depth analysis and ECG tests from the comfort of home</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Entire IoT backend for high volume sensor data
              ingest</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Security compliances for medical devices</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Zero downtime platform</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Lead Management app for Unify Ventures</h1>
          <p class="para-desc text-dark">Presenting our latest innovation: the Lead Management App designed for Unify
            Ventures' marketing and sales teams. As a B2B SaaS offering for real estate channel partners, it streamlines
            lead management processes, empowering teams to efficiently nurture and convert leads with ease.</p>
          <ul class="list-unstyled text-primary">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Hybrid app developed for both iOS and Android
            </li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Serverless backend</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Whatsapp, SMS and VOIP integration
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/03-portrait.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <div class="container pt-4 mt-100 mt-60">
    <div class="row justify-content-center" id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Overall, we've delivered <span class="text-primary">
              <span class="counter-value" [CountTo]="10" [from]="0" [duration]="5"></span>+</span> successful customer
            projects</h4>
          <p class="text-dark para-desc mx-auto mb-0">Transform your vision into reality by collaborating with us to
            develop custom software solutions tailored to your needs. Whether it's a dynamic website, feature-rich
            mobile application, or robust web platform, we'll bring your ideas to life through seamless integration and
            expert development.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Reach out to us now and start the conversation</h4>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a routerLink="/page-contact-one" class="btn btn-light">Get in touch</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Section End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->