import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentationComponent } from './core/components/documentation/documentation.component';
import { IndexClassicSaasComponent } from './core/components/index-classic-saas/index-classic-saas.component';
import { IndexSingleProductComponent } from './core/components/index-single-product/index-single-product.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { PageContactOneComponent } from './core/components/page-contact-one/page-contact-one.component';
import { PagePrivacyComponent } from './core/components/page-privacy/page-privacy.component';
import { PageTermsComponent } from './core/components/page-terms/page-terms.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';

const routes: Routes = [
  {
    path: '', component: MasterPageComponent,
    children: [
      { path: '', component: IndexSingleProductComponent },
      { path: 'Smartmeter', component: DocumentationComponent },
      { path: 'IT_Solutions', component: IndexClassicSaasComponent },
      { path: 'index-single-product', component: IndexSingleProductComponent },
      { path: 'page-contact-one', component: PageContactOneComponent },
      { path: 'page-privacy', component: PagePrivacyComponent },
      { path: 'page-terms', component: PageTermsComponent },
      { path: '#', component: SwitcherComponent },
    ]
  },

];

@NgModule({
  providers: [
   
  ],
imports: [RouterModule.forRoot(routes, {
  useHash: true, scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"
})],
exports: [RouterModule]
  
})
export class AppRoutingModule { }
