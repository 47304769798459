import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-classic-saas',
  templateUrl: './index-classic-saas.component.html',
  styleUrls: ['./index-classic-saas.component.css']
})

/***
 * Classic Saas Component
 */
export class IndexClassicSaasComponent implements OnInit {

  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true;
  
  constructor(private modalService: NgbModal) { 
  }
  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-desktop h2 text-primary", // Icon for frontend tech stack
      title: "Frontend Technologies",
      description: "AngularJS, ReactJS, WordPress, HTML, CSS"
    },
    {
      icon: "uil uil-server-network h2 text-primary", // Icon for backend tech stack
      title: "Backend Technologies",
      description: "Node.js, Python Flask, .NET"
    },
    {
      icon: "uil uil-database h2 text-primary", // Icon for database tech stack
      title: "Database Technologies",
      description: "MySQL, PostgreSQL, MongoDB, DynamoDB, TimestreamDB, InfluxDB, Redis Cache"
    }
  ];

  ngOnInit(): void {
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
