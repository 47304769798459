import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { FeatherModule } from 'angular-feather';

import { ServicesComponent } from './services/services.component';


@NgModule({
  declarations: [
    ServicesComponent,
  ],
  imports: [
    CommonModule,
    CarouselModule,
    FeatherModule,
    RouterModule
  ],
  exports: [
    ServicesComponent,
  ]
})

export class SharedModule { }
