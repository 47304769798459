<!-- Hero Start -->
<section class="bg-half-170 bg-light d-table w-100" id="home" style="background-image: url('assets/images/shop/privacy.jpg'); background-size: cover;">
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title" style="font-weight: bold;">PRIVACY POLICY </h4>
          <ul class="list-unstyled mt-4 mb-0">
            <li class="list-inline-item h6 date"> <span class="text-dark">Last Revised :</span> 25 December
              2023</li>
          </ul>
        </div>
      </div> <!--end col-->
    </div><!--end row-->
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Overview :</h5>
            <p class="text-dark">This Privacy Policy applies only to the information collected through Connectchip
              Smart Solutions websites located at www.connectchip.in or through the Applications downloaded from these
              websites. By visiting these Websites and/or downloading any Applications (“Apps”) there from, you accept
              the practices described in this Privacy Policy for any information you provide and have provided in the
              past through the Websites/Apps. If you are uncomfortable with our policies and practices, please do not
              provide any personal information to us through these Websites/Apps. As used in this Privacy Policy, the
              terms “you” and “your” refer to each visitor to the Website and to any subscriber that downloads
              Connectchip Utilities Apps, and the terms “we,” “our,” “us,” and “Connectchip” refer to Connectchip Smart
              Solutions.</p>
            <p class="text-dark">Connectchip understands how important privacy is to our users and subscribers. We are
              committed to protecting your privacy and want to make sure that you understand how your personal
              information is used. What we collect. When you subscribe to our services or use our Website/ Apps, we ask
              for basic information such as your name, contact information, physical address and other autobiographical
              information. We keep track of your subscribed utility, access and activities so we can understand our
              users/subscribers needs and evaluate the effectiveness of our offerings and products.</p>
            <p class="text-dark">Information Sharing. We share your personal information only with the builder/
              building association to whom we are providing services or to our associate corporate entities engaged in
              helping us provide these services. We may also release personal subscriber information when we are
              required to do so by law, or when necessary or appropriate to comply with legal process or to protect or
              defend Connectchip Smart Solutions. We do not sell or rent subscriber information to telemarketers,
              mailing list brokers or any other companies.</p>
            <p class="text-dark">For more detailed information about our privacy practices, and about privacy on the
              Website/Apps, please review the frequently asked questions below. To ask questions about your personal or
              other subscriber information, please contact us at: Address: #370/40 “Ground Floor” 13th Cross Vyalikaval
              Malleshwaram Bangalore 560003 Phone: +91 7338373399
              Email: connect@connectchip.in</p>

            <h5 class="card-title">Connectchip Website/Apps Privacy: Questions and Answers</h5>
            <p class="text-dark">The following Questions and Answers address Connectchip Website and Applications
              privacy issues in more detail. We want to make sure that you understand the types of information that are
              collected at Connectchip and through the Website/Apps and the ways in which that information is used and
              protected. Specifically, this privacy policy answers the following frequently asked questions:</p>

            <ul class="list-unstyled text-dark">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Do I have to submit any personal information in order to use the Website/Apps?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Do the Website/Apps collect non-personal information, using "cookies" or otherwise?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                How is this information used, and can I control its use?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Is any information shared with other companies or organizations?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                How do the Website/Apps protect security and confidentiality?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Will this privacy policy ever change?
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Who can I contact with questions or comments about privacy issues?
              </li>
            </ul>

            <h5 class="card-title">1. Do I have to submit any personal information in order to use the Website/Apps?
            </h5>
            <p class="text-dark">If you want to take advantage of being a user of Connectchip Smart Solutions
              technology or use of the services available on the Website/Apps, we will request information that allows
              us to identify you. You will receive emails and we may also ask for personal information when you
              participate in other activities or services on the Website/Apps, such as surveys. Again, regardless of
              whether you are visiting/using the Apps, Website or an affiliated site, you do not have to disclose any
              personal information if you do not want to. We are only interested in offering you convenient services
              that match your needs.</p>

            <h5 class="card-title">2. Do the Website/Apps collect non-personal information, using "cookies" or
              otherwise?
            </h5>
            <p class="text-dark">We also collect other types of information that do not allow us to identify your name,
              email address, or other personal characteristics. This "non-personal information" includes the number
              assigned to your computer whenever you access the Internet ("IP address"), and the type of browser you
              use. None of this non-personal information allows us to identify or contact you. We use this information
              to help us evaluate how the Website/Apps are being used. By gathering this information, we can learn,
              among other things, how many people visit the Website, use the Apps, etc. We may also assign a "cookie" to
              your computer in some situations. Like an IP address, a "cookie" is a kind of online identification tag
              that allows us to recognize your computer each time you visit the Website. We may use similar tools
              similar to Google Analytics, in the future, so as to capture the GUID (Globally Unique Identifier) for
              each user, region or chapter, age range, gender and user type. The information is may be also stored for
              web analytics. In addition, we may use cookies to store the user login name when he or she chooses the
              “Remember Me” option on the login page. We may also use an authentication cookie that lets the Website
              know the user has a valid login. If a user disables session cookies, the Website/Apps may not work. Users
              can only disable first party and third party cookies.</p>

            <h5 class="card-title">3. How is this information used?
            </h5>
            <p class="text-dark">Our primary purpose in collecting this information is to provide you with the services
              you request, allow you to interact with us, the building association and other personnel, both on the
              Website/Apps and offline. As explained above, for example, we ask for information so that we can send you
              detailed billing for utilities used, immediate alerts on the usage of utilities, providing personalised
              tips, analytics on usage patterns, high-usage alarms, tracking budgets, and/or raise any concerns in usage
              patterns.</p>

            <h5 class="card-title">4. Is any information shared with other companies or organizations?
            </h5>
            <p class="text-dark">Connectchip takes seriously its responsibility to keep your personal information
              confidential and private. We do not sell or rent any of our subscribers' personal information to
              telemarketers, mailing list brokers, or any other companies. However, like most organizations, we also
              hire companies to provide certain administrative services that require access to Subscriber information,
              such as processing address labels, managing databases, and sending information by sms or online. We may
              further share these details with our marketing partners for promotion of our own products and services. We
              require all of these service providers to also hold subscriber information in confidence. These providers
              are contractually required to maintain the security and confidentiality of all Subscriber information, and
              are prohibited from using that information for any purpose other than providing the services specified in
              their contracts with Connectchip. We may, at our discretion, audit and monitor these companies to ensure
              that the Subscriber information is protected. We may also disclose personal information when required to
              do so by law or when such action is necessary or appropriate to comply with legal processes served on
              Connectchip, to protect and defend the rights or property of Connectchip, or to protect the personal
              safety of users of the Website/Apps. We reserve the right to contact the appropriate authorities in our
              discretion when visitors' activities appear to be illegal or inconsistent with our policies.</p>

            <h5 class="card-title">5. How do the Website/Apps protect security and confidentiality?
            </h5>
            <p class="text-dark">In order to protect your personal information, we use technologies and processes such
              as encryption, access control procedures, network firewalls, physical security and other measures. These
              measures increase, but do not guarantee, the security and privacy of information traveling to, from, and
              within the Website/Apps. Only our authorized employees, agents or independent contractors carrying out
              permitted business functions are allowed to access personal information. Employees who violate our privacy
              access policies may be subject to disciplinary actions, including termination when appropriate. In order
              to receive some services, we may require you to use a password to protect your subscriber account from
              unauthorized access by others. You are responsible for maintaining the secrecy of your unique password and
              account information, and for controlling access to your communications on the Website/Apps at all times.
              If you do allow others, including family or household members, to access Connectchip Subscribers-only
              services through your personal password or to use your Subscriber account number, please understand that
              you are responsible for the actions of those individuals. Please note that emails, instant messaging and
              similar means of communication with others are not encrypted, and we advise you not to communicate any
              confidential information through these means.</p>

            <h5 class="card-title">6. Will this privacy policy ever change?
            </h5>
            <p class="text-dark">This privacy policy describes the types of information we currently collect, and the
              ways we use and protect that information. If you are uncomfortable with our policies and practices, please
              do not provide any personal information to us through the Website/Apps. However, this may prevent/hinder
              you from using our Website/Apps. From time to time, we may collect different types of information and use
              that information in different ways - for example, when we add features or services to the Website/Apps. In
              these cases, we may edit this policy to explain the new practices. Because protecting Subscribers’ privacy
              is very important to us, we do not expect major changes in policy. However, if we do make significant
              changes in our practices, we will include announcements on the Website home page and/or the Appsso that
              you will know to review the revised policy. If you object to any changes, you may delete your account by
              contacting us. By continuing to use the Website/Apps after notice of changes has been sent to you or
              published on the site, you are consenting to the changes.</p>

            <h5 class="card-title">7. Who can I contact with privacy questions?
            </h5>
            <p class="text-dark">If you have any questions or comments about your Subscriber information or this
              privacy policy, please send an email to connect@connectchip.in. We will do our best to investigate any
              question you may have and respond promptly to your concerns.</p>

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->