<!-- Hero Start -->
<section class="home-slider position-relative" id="home">
  <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="bg-home d-flex align-items-center position-relative">
        <!-- Video Overlay -->
        <div class="slide-inner d-flex align-items-center vh-100"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1;">
          <video autoplay loop muted playsinline style="width: 100%; height: 100%; object-fit: cover;">
            <source src="assets/connectchip_banner13.mp4" type="video/mp4">
            <!-- You can provide additional source elements for different video formats if needed -->
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <!--end slide-->
    </div>
  </div>
</section>
<!--end section-->
<!-- Hero End -->


<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="features-absolute">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-water d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a routerLink="/documentation" class="title text-dark">Smart Water Metering
                      Solutions</a></h5>
                  <p class="text-dark">IoT Water Metering Solutions with Web & Mobile Apps for Easy Water Consumption
                    Tracking</p>
                  <a routerLink="/documentation" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-md-0 pt-2 pt-md-0">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-mobile-android d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a routerLink="/index-classic-saas" class="title text-dark">Mobile Application
                      Development</a>
                  </h5>
                  <p class="text-dark">Tailored Hybrid Mobile Apps for Android & iOS, leveraging expertise in Ionic,
                    ReactNative, Node.js, HTML, and CSS</p>
                  <a routerLink="/index-classic-saas" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div
                class="card features feature-clean feature-primary explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center">
                <div class="icons text-primary text-center mx-auto">
                  <i class="uil uil-globe d-block rounded h3 mb-0"></i>
                </div>
                <div class="card-body p-0 content">
                  <h5 class="mt-4"><a routerLink="/index-classic-saas" class="title text-dark">Webapp / Website Development</a>
                  </h5>
                  <p class="text-dark">Custom Web Development matching client needs. Expertise in AngularJS, ReactJS,
                    HTML, CSS, and hosting.
                  </p>
                  <a routerLink="/index-classic-saas" class="text-primary">Read More <i
                      class="uil uil-angle-right-b align-middle"></i></a>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Subscribe Start -->
<section class="section" style="background: url('assets/images/single/1.jpg') top center;">
  <div class="container position-relative">
    <div class="row">
      <div class="col-lg-5 col-md-6">
        <div class="app-subscribe text-center text-md-left" style="margin-top: 20%;">
          <!-- <img src="assets/images/single/camera1.png" class="img-fluid" alt=""> -->
          <img src="assets/images/single/1.png" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title text-center text-md-start">
          <h1 class="fw-bold text-white title-dark mb-3">Smart Water Meter</h1>
          <p class="fw-bold text-white">Connectchip's high-precision IoT-enabled smart water meters offer accurate
            water measurement, proudly made in India. Utilizing wireless LoRa and battery technology, paired with robust
            mobile and web apps for seamless consumption data monitoring</p>
          <ul class="list-unstyled text-white fw-bold">
            <li class="mb-1"><span class="text-white h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Helical Rotor Design</li>
            <li class="mb-1"><span class="text-white h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Accurate Detection</li>
            <li class="mb-1"><span class="text-white h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>FCRI tested</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Subscribe End -->

<section class="section overflow-hidden" style="padding: 10px;">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-5">
        <div class="text-center text-md-start">
          <img src="assets/images/app/111.png" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">The Customer App and <br> Dashboard</h1>
          <p class="para-desc text-dark">Our mobile application and dashboard interface seamlessly gather sensor data,
            presenting users with real-time insights and analytics. Track consumption patterns, identify trends, and
            optimize water usage for sustainability</p>
          <ul class="list-unstyled text-dark">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Monitor water consumption</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Predict consumption trends</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Diagnose and reduce water consumption</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">The Admin App and<br>Dashboard</h1>
          <p class="para-desc text-dark">Our web application and dashboard empower administrators and project owners
            with comprehensive sensor data visualization and analytics. Monitor consumption trends, access real-time
            insights, and effectively manage water usage for enhanced efficiency and sustainability. Admins can also
            monitor project-wise data, set meter rate cards, export data to Excel, manage helpdesk tickets, and
            establish billing rate cards for streamlined operations.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 order-1 order-md-2">
        <div class="text-center text-md-end">
          <img src="assets/images/app/webapp.png" class="img-fluid" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Start -->
<section class="section" style="background: url('assets/images/single/1.jpg') top center;">
  <div class="bg-overlay bg-linear-gradient-2"></div>
  <div class="container">
    <div class="row align-items-end">
      <div class="col-lg-8 col-md-6 col-12">
        <div class="section-title">
          <h1 class="title text-white title-dark mb-4 fw-bold">Our Cutting Edge Software Solutions</h1>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End -->

<section class="section">
  <!-- About Start -->
  <div class="container mt-30 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/app/pocketheart.png" class="img-fluid" alt="work-image">
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/app/flowerstallapp-front.png" class="img-fluid" alt="work-image">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/app/zveno2.png" class="img-fluid" alt="work-image">
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <ul class="list-unstyled text-dark">
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Leveraging the latest, highly secure technology
              stacks</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Built on completely serverless architecture for
              optimal performance and cost-effectiveness</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Offering the industry's lowest hosting costs
              while ensuring top-notch reliability</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Highly scalable infrastructure to accommodate
              your growing needs effortlessly</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Fully responsive UI tailored to your custom
              requirements for an exceptional user experience</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Seamless integrations with multiple platforms,
              including SMS, WhatsApp, and email, for enhanced communication</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>High-performance applications engineered for zero
              downtime, ensuring
              uninterrupted service</li>
            <li class="mb-0"><span class="text-primary h4 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Robust security measures implemented throughout
              to safeguard your data and
              privacy</li>
          </ul>

          <div class="watch-video mt-4 pt-2">
            <a routerLink="/index-classic-saas" target="_blank" class="btn btn-primary m-1 me-2">Read More
              <i-feather name="chevron-right" class="fea icon-sm"></i-feather>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->



<!-- Testimonial Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-1 pb-1">
          <h4 class="title mb-4">Clients' Feedback</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 pt-2 mt-2 text-center">
        <owl-carousel-o id="customer-testi" [options]="customOptions" autoplay=true>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"We conducted a successful smart water meter Proof of Concept (POC)
                  as part of our project. Impressed with the results, we proceeded with a full-fledged installation. The
                  solution has proven to be highly effective, enabling us to track usage and achieve a remarkable 20%
                  reduction in consumption."</p>
                  <br><p> </p>
                  <!-- <br><p> </p> -->
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Vasanth <small class="text-muted">Property Manager, Ajmera
                    Villows</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"We turned to the Connectchip team to transition from our
                  traditional billing software to a cloud-based solution. Their swift development exceeded our
                  expectations. We've been utilizing the software for 2 years, experiencing zero downtime and enjoying
                  the lowest hosting costs."</p>
                  <br><p> </p>
                  <!-- <br><p> </p> -->
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Balaji Anjaneya <small class="text-muted">Founder, BMA</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"Connectchip really nailed it with our smart silo monitoring
                  solution. We're stoked about the IoT device, app, and how everything came together. Their on-time
                  delivery made us really happy"</p>
                  <br><p> </p>
                  <br><p> </p>
                  <br><p> </p>
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Sudev K S <small class="text-muted">CEO, Zveno Infratech</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"Our website was up and running in just 3 days, exactly how we
                  envisioned it, including hosting. We're thrilled with the outcome and impressed by the speedy
                  delivery!"</p>
                  <br><p> </p>
                  <br><p> </p>
                  <br><p> </p>
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Kiran G <small class="text-muted">CMO, Elrange Energy</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"Sujatha Healthcare's Pocket Heart app was developed swiftly,
                  meeting our needs precisely. It's been a game-changer for us, and we couldn't be happier with the
                  speed and quality of delivery!"</p>
                  <br><p> </p>
                  <br><p> </p>
                  <br><p> </p>
                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Shivaraj P D <small class="text-muted">CTO, Sujatha Healthcare</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi m-2 text-center rounded shadow border-0">
              <div class="card-body">
                <p class="text-muted h6 fst-italic">"For the past 6 months, we've been utilizing a smart water meter in
                  our complex, and it's been fantastic. This solution has enabled us to accurately track and bill water
                  consumption within our apartments. It's also made us more aware of our consumption patterns,
                  ultimately leading to a significant reduction in our community's water demand."</p>

                <ul class="list-unstyled mb-0 mt-3">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <h6 class="text-primary">- Supriya K <small class="text-muted">Association, Devagiri
                    Tulips</small></h6>
              </div>
            </div>
            <!--end customer testi-->
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testimonial End -->

<!-- Start Contact -->
<section class="section pb-0">
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-8 col-md-8 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title" style="font-weight: bold;">REACH OUT TO US!</h4>
            <div class="custom-form mt-3">
              <form method="post" name="myForm" onsubmit="return validateForm()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label">Your Phone <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Phone :">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                      </div>
                    </div>
                  </div>


                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Subject</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Comments <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 mx-auto">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!--end container-->
</section>
<!--end section-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->