<!-- Hero Start -->
<section class="bg-home d-flex align-items-center"
    style="background: url('assets/images/single/home-banner3.png') center center; height: auto;" id="home">
    <div class="bg-overlay bg-overlay-white"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
                <div class="title-heading margin-top-100">
                    <h1 class="display-4 fw-bold mb-3">Smart Water Meter</h1>
                    <p class="para-desc mx-auto text-dark">Streamline Water Management with Precision Technology</p>
                </div>
                <div class="home-dashboard">
                    <img src="assets/images/single/1.png" alt="" class="img-fluid mover">
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Feature Start -->
<section class="section bg-light" style="padding: 10px;">
    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <img src="assets/images/single/camera.png" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-lg-3">
                    <h4 class="title mb-4">Why Water Metering and Why Choose Us?</h4>
                    <p class="text-dark">In India's urban areas, water consumption is steadily rising while freshwater
                        sources are limited. Responsible water usage is crucial, and our unique smart metering solutions
                        help track every drop used.</p>
                    <ul class="list-unstyled text-dark">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span><b>Track</b> - The first step
                            to water saving is to first have hardware/software infrastructure to monitor water
                            consumption in our community/home
                        </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span><b>Analyse</b> - Understanding
                            data about water consumption trends and analyzing it will help us understand where we can
                            conserve water
                        </li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span><b>Reduce</b> - As a
                            psychological effect monitoring our water consumption trends and billing as per usage helps
                            us reduce water consumption
                        </li>
                    </ul>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-search d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">Accurate Detection</h5>
                <p class="text-dark mb-0">Our flow sensors are FCRI certified, ensuring highly accurate measurements.
                </p>
            </div>
            <!--end col-->

            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-battery-bolt d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">Battery Based</h5>
                <p class="text-dark mb-0">Our smart water meters operate on batteries, with a minimum lifespan of one
                    year.</p>
            </div>
            <!--end col-->

            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-wifi d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">Wireless LoRa Based</h5>
                <p class="text-dark mb-0">Utilizing LoRa protocol, our meters wirelessly transmit consumption data.</p>
            </div>
            <!--end col-->

            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-shield-check d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">IP67 Build</h5>
                <p class="text-dark mb-0">Designed with IP65 rating, our sensors are built for outdoor durability.</p>
            </div>

            <div class="col-md-3 col-6 p-4 text-center">
            </div>

            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-location-point d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">Made in India</h5>
                <p class="text-dark mb-0">Our sensors, circuit boards, and applications are proudly manufactured in
                    India.</p>
            </div>

            <div class="col-md-3 col-6 p-4 text-center">
                <i class="uil uil-mobile-android d-block rounded h1 mb-0"></i>
                <h5 class="mt-2">Mobile/Web Apps</h5>
                <p class="text-dark mb-0">Enjoy interactive mobile and web apps for monitoring consumption trends,
                    billing, and alerts, available on both Android and iOS platforms.</p>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h2 class="title mb-4">Smart Water Meter Savings Explained</h2>
                </div>
            </div>
            <!--end col-->
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="timeline-page pt-2 position-relative">
                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                                    Reduce Water Consumption</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div
                                    class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">Communities with smart water meters tend to
                                            reduce water consumption by 10 to 30 %</h5>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div
                                    class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">Reduced Water Consumption also means lesser
                                            Energy is now used by Pumps/Motors etc to pump water to flats</h5>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div
                                    class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">
                                    Energy Savings
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                                    Treatment Savings</div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div
                                    class="card event event-description-right rounded shadow border-0 overflow-hidden float-left">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">Lesser Water Consumption also directly relates
                                            to reduced treatment costs like STP/WTP power consumption and chemical costs
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->

                    <div class="timeline-item mt-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-1 order-2">
                                <div
                                    class="card event event-description-left rounded shadow border-0 overflow-hidden float-left">
                                    <div class="card-body">
                                        <h5 class="mb-0 text-capitalize">A reduction in below three factors also
                                            directly relates to lesser service/maintenance and repair costs for all
                                            water related machinery</h5>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                            <div class="col-lg-6 col-md-6 col-sm-6 order-sm-2 order-1">
                                <div
                                    class="duration duration-right rounded border p-2 px-4 position-relative shadow text-left">
                                    Maintenance Savings
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end timeline item-->
                </div>
                <!--end timeline page-->
                <!-- TIMELINE END -->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
</section>

<!-- Showcase Start -->
<section class="section pt-0">
    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Monitoring App</h4>
                    <p class="text-dark para-desc mb-0 mx-auto">Stay connected and monitor your water consumption
                        on-the-go with our intuitive Smart Water Metering mobile app. Track usage, receive alerts, and
                        manage your water efficiency effortlessly from your smartphone.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class=" mt-4 pt-2 text-center">
                <ul ngbNav #nav="ngbNav"
                    class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Dashboard</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/app/smart_utilities1.png" class="img-fluid mx-auto d-block"
                                        alt="">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i>
                                            Dashboard Page</h4>
                                        <p class="text-dark">The mobile app smart water meter dashboard page provides
                                            users with real-time insights. With intuitive design and user-friendly
                                            interface, it offers:</p>
                                        <ul class="list-unstyled text-dark">
                                            <li class="mb-1"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Current day
                                                and month-wise consumption, both aggregated and device-wise.</li>
                                            <li class="mb-1"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Previous
                                                day and month-wise consumption, both aggregated and device-wise.</li>
                                            <li class="mb-1"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Day-wise
                                                and month-wise consumption trend graphs.</li>
                                            <li class="mb-1"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Device
                                                consumption last updated timestamp</li>
                                        </ul>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Reports</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/app/smart_utilities2.png" class="img-fluid mx-auto d-block"
                                        alt="">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i>
                                            Reports Page</h4>
                                        <p class="text-dark">The reports page on the mobile app offers users detailed
                                            insights into their water consumption patterns and usage trends. Key
                                            features include:</p>
                                        <ul class="list-unstyled text-dark">
                                            <li class="mb-0"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Daywise
                                                water consumption based on the selected month</li>
                                            <li class="mb-0"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Aggregated
                                                daywise consumption</li>
                                            <li class="mb-0"><span class="text-primary h5 me-2"><i
                                                        class="uil uil-check-circle align-middle"></i></span>Device-wise
                                                daywise consumption</li>
                                        </ul>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                    <li ngbNavItem>
                        <a ngbNavLink class="rounded">
                            <div class="text-center py-2">
                                <h6 class="mb-0">Helpdesk</h6>
                            </div>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <img src="assets/images/app/smart_utilities3.png" class="img-fluid mx-auto d-block"
                                        alt="">
                                </div>
                                <!--end col-->

                                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <div class="section-title text-md-start">
                                        <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i>
                                            Helpdesk page</h4>
                                        <p class="text-dark">The helpdesk feature provides users with comprehensive
                                            support and assistance, ensuring a seamless experience and timely resolution
                                            of issues. Here's how it benefits users:</p>
                                            <ul class="list-unstyled text-dark">
                                                <li class="mb-2"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Submit new helpdesk tickets to report any issues or observations with the app or meter readings.</li>
                                                <li class="mb-2"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Access existing helpdesk tickets to view their status, details, and updates, facilitating efficient issue resolution.</li>
                                            </ul>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
            </div>
        </div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->



<!-- Gallery Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/install1.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/install2.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/install3.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="card customer-testi m-2 text-center rounded shadow border-0">
                            <img src="assets/images/single/install4.jpg" />
                        </div>
                        <!--end customer testi-->
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h4 class="title mb-4">Our Live Installations</h4>
                    <ul class="list-unstyled text-dark">
                        <li><i class="mdi mdi-check me-2"></i>Witness our cutting-edge technology in action through our
                            live installations across various urban landscapes</li>
                        <li><i class="mdi mdi-check me-2"></i>Experience firsthand the seamless integration and
                            performance of our innovative solutions in real-world settings</li>
                        <li><i class="mdi mdi-check me-2"></i>Dive into our portfolio of live installations showcasing
                            the reliability and effectiveness of our products</li>
                        <li><i class="mdi mdi-check me-2"></i>Discover the success stories behind our live
                            installations, demonstrating the transformative impact of our technology</li>
                    </ul>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Gallery End -->


<!-- FAQ n Contact Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h2 class="title mb-4">FAQ</h2>
                </div>
            </div>
            <!--end col-->
        </div>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">How accurate are your sensors ?</h5>
                        <p class="answer text-dark mb-0">All our sensors are FCRI certified and have an error rate
                            lower than +-3 percent</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> What type of water can meters handle ?</h5>
                        <p class="answer text-dark mb-0">Our Sensors are meant for Freshwater Applications. In case
                            projects have Built in WTP then no issues else we will use a 10 micron filter at meters to
                            ensure longer life. However all our sensors are very resistant to corrosion dust etc.</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0">We have a pressure pump system, will your sensors handle it ?</h5>
                        <p class="answer text-dark mb-0">Our sensors can handle pressures upto 25 bar and typical
                            pressure pumps dont exceed 4 to 5 bar</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> Will there be any wiring ?
                        </h5>
                        <p class="answer text-dark mb-0">All our devices are completely battery based and wireless,
                            there wont be any wires etc in shafts</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> How frequent are maintenance activities ?
                        </h5>
                        <p class="answer text-dark mb-0">All our Water meters come will be services anually to manage
                            things such as battery replacement, sensor servicing etc</p>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="d-flex">
                    <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                    <div class="flex-1">
                        <h5 class="mt-0"> My flat has multiple water inlets on different sides of the apartment, how
                            will the solution address this ?
                        </h5>
                        <p class="answer text-dark mb-0">Our solution deploys multiple sensors to each inlet of the
                            apartment and all the consumption data is pushed wirelessly to our servers and aggregated
                            and displayed in our web and mobile applications</p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Have a question ? Get in touch!</h4>
                    <div class="mt-4 pt-2">
                        <a routerLink="/page-contact-one" class="btn btn-primary">Contact us <i
                                class="mdi mdi-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->